import { reactive } from 'vue';
import cardMes from '@/components/cardMes.vue';
import { useNavPathStore } from '@/store/modules/navPath';
export default {
  components: {
    cardMes
  },
  setup() {
    const store = useNavPathStore();
    const flags = reactive({
      iconFlag: true,
      introFlag: true
    });
    return {
      store,
      flags
    };
  }
};